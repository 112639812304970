import "./TasksSection.css";

import React, {useState} from "react";
import TaskLineItem from "./TaskLineItem";

const TasksSection = (props) => {
    
    return (
        <div className={"tasks-section-container"}>
            <p className={"tasks-section-header"}>{props.header}</p>
            {props.tasks.map(task =>
                <TaskLineItem key={task.id} task={task} selected={props.selected === task} handleSetSelected={props.handleSetSelected} handleUpdate={props.handleUpdate} config={props.config} />
            )}
        </div>
    )
}

export default TasksSection